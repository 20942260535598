/* CONFIRM PAYMENT  */
.payment {
  background-color: #f8f8f8 !important;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  place-content: center;
  padding: 20px;
}

.payment h1 {
  font-size: 1.5rem;
  color: #eb5017;
  font-weight: 500;
}

.payment article {
  background-color: #fff;
  min-height: 50vh;
  max-width: 80%;
  border-top: 3px dashed #eb5017;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 10px #00000022;
  gap: 4rem;
  border-radius: 15px;
  padding: 4rem;

  /* width: 300px; */
}

.proceed-btn,
.back-btn {
  background-color: #eb5017;
  padding: 18px 25px;
  border-radius: 10px;
  font-size: 1.25rem;
  color: white !important;
  border: none;
  outline: none;
  width: 100%;
}

.back-btn {
  background-color: #ff0000;
}
.gif {
  width: 300px;
}

.card {
  background-color: #ffffff !important;
  padding: 24px;
  border-radius: 10px;
  width: 100%;
  max-width: 489px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  align-items: center;
}

.icon {
  width: 111px;
  height: 111px;
  border-radius: 111px;
  font-size: 53px;
  display: grid;
  place-content: center;
}

.icon[data-loading="true"] {
  color: #eb5017;
  background-color: #eb50171c;
}

.icon[data-success="true"] {
  color: #1ea355;
  background-color: #1ea3551c;
}

.icon[data-error="true"] {
  color: #fe574d;
  background-color: #fe574d1c;
}
